import React, { Component } from "react"

/* Socket IO */
import { io } from "socket.io-client"

/* Constants */
import { endpoint, DEALER_SOCKET_TOKEN } from './constants/env'

/* Pages */
import { Pin, Game } from './pages'

/* JWT */
import { isExpired, decodeToken } from "react-jwt"


/* Entry Point */
class App extends Component {


    constructor() {
        super()

        const token = localStorage.getItem("token")

        this.state = {
            token: token ? token : ''
        }

        /* Start SOCKET connection */
        this.socket = io(endpoint, { auth: { token: `${DEALER_SOCKET_TOKEN}` } })
    }



    /* ON CONNECT -> SEND DEALER INFO & LISTEN DEALER CONNECTION */
    componentDidMount = () => {

        /* Fields */
        const { token } = this.state

        /* Token status */
        const isTokenExpired = isExpired(token)

        /* Check token */
        if (token !== '' && !isTokenExpired) {
            const dealer = decodeToken(token)
            this.socket.emit("dealerInfo", { id: dealer.id, name: dealer.name })
        }

        /* LISTENER WHEN DEALER CONNECTED VIA SCANNER */
        this.socket.on("monitorDealerConnect", data => {
            this.setState({ token: data.token })
            localStorage.setItem("token", data.token)
        })

        this.socket.on("disconnect", () => {
            this.logout()
        })

    }


    /* ON LEAVE -> DISCONNECT FROM SOCKET */
    componentWillUnmount = () => {
        this.socket.disconnect()
        this.logout()
    }


    /* SET TOKEN */
    setToken = token => {
        this.setState({ token })
        localStorage.setItem("token", token)
    }


    /* LOGOUT ACTION */
    logout = () => {
        this.setState({ token: '' })
        localStorage.removeItem("token")
        this.socket.emit("dealerInfo", null)
    }


    render = () => {

        /* Fields */
        const { token } = this.state

        /* Token status */
        const isTokenExpired = isExpired(token)

        /* PIN CODE */
        if (token === '' || isTokenExpired) {
            return <Pin socket={this.socket} setToken={token => this.setToken(token)} />
        }

        /* GAME */
        return <Game socket={this.socket} logout={() => this.logout()} />
    }

}


export default App