const endpoint = "https://evpb.rekopgames.com"

/* SOCKET TOKEN */
const DEALER_SOCKET_TOKEN = "994214ad-2bb4-49d0-833b-e90cd89b57e4"

/* DEALER MONITOR TOKEN */
const DEALER_MONITOR_TOKEN = "77beac39-f0ce-412e-a10f-c8bb887f5dec"

/* POINTS */
const startpoint = `${endpoint}/api/`
const mediapoint = `${endpoint}/`

export { endpoint, mediapoint, startpoint, DEALER_SOCKET_TOKEN, DEALER_MONITOR_TOKEN }
