import React, { Component } from "react"

/* JWT */
import { decodeToken } from "react-jwt"

/* Sounds */
import { sound } from "../constants"

/* Modal */
import Modal from 'react-modal'


/* Fields */
const GAME = 'game'
const NOPLAYER = 'noplayer'
const WAITING = 'waiting'

/* GAME STATUS */
const SIXTH = 'fifth'
const BET = 'bet'
const EXCHANGE = 'exchange'
const PASS = 'pass'
const BUY = 'buy'


/* Default Modal Settings */
Modal.setAppElement('#root')


/* Page GAME */
class Game extends Component {


    constructor() {
        super()

        this.state = {
            status: NOPLAYER,
            username: null,
            boxes: 0,
            active: 1,
            dealer: [],
            player: [],
            action: [0, 0],
            fifthCard: [],
            isExchange: [0, 0],
            exchangedCards: [],
            activePositions: [],
            isPurchase: false,
            purchaseCard: [],
            isOpen: false,
            endGame: false,
            game: [0, 0],
            dealerGame: null,
            isPaused: false,
            messages: [],

            current_time: new Date().toLocaleTimeString()
        }

        this.position = 0
    }

    componentDidMount = () => {
        this.game()
        this.intervalId = setInterval(this.updateTime, 1000)
    }

    componentWillUnmount = () => {
        clearInterval(this.intervalId)
    }

    updateTime = () => {
        const newTime = new Date().toLocaleTimeString()
        this.setState({ ctime: newTime })
    }

    /* GAME EVENTS */
    game = () => {

        const { socket } = this.props

        /* Dealer Status */
        socket.on("dealerMonitorStatus", data => {

            this.setState({ status: data.status, boxes: data.boxes })

            if (data.status === NOPLAYER) {
                this.clear()
                this.setState({ username: null })
            }
            else {
                sound.audio('choise', 1)
            }

            if (data.username) {
                this.setState({ username: data.username })
            }

        })


        socket.on("endGame", () => {
            this.setState({ endGame: true })
        })

        socket.on("playerName", data => {
            this.setState({ username: data })
        })

        socket.on("clearDealerMonitor", () => {
            this.clear()
        })

        /* Get a dealer card */
        socket.on("monitorDealer", data => {
            const { dealer, active, boxes } = this.state
            const checkIndex = (boxes + 1) * 4
            let cards = dealer
            cards.push(data)
            this.setState({ dealer: cards, active: active === checkIndex ? (checkIndex + boxes + 1 ): active + 1 })
        })

        socket.on("monitorDealerCards", cards => {
            this.setState({ dealer: cards })
        })



        /* Get a player card */
        socket.on("monitorPlayer", data => {
            const { player, active } = this.state
            const { card, index } = data
            let cards = player
            let list = cards[index] ? cards[index] : []
            list.push(card)
            cards[index] = list
            this.setState({ player: cards, active: active + 1 })
        })

        socket.on("dealerActionSet", data => {
            const { action } = this.state
            const { box } = data.params
            setTimeout(() => {
                let demo_action = action
                demo_action[box] = data.key
                this.setState({ action: demo_action })
            })
        })

        /* Get game action */
        socket.on("dealerAction", data => {

            const { box } = data.params

            const { isExchange, exchangedCards, boxes, action, game } = this.state

            const card_divide = boxes + 1
            // PLAY SOUND
            setTimeout(() => {
                let demo_action = action
                demo_action[box] = data.key
                this.setState({ action: demo_action })

                let demo_game = game

                demo_game[box] = 0

                if (data.key === EXCHANGE) {

                    const { cards, box } = data.params

                    let activePositions = []
                    let isExchangeDemo = isExchange
                    let exchangeCardsDemo = exchangedCards
                    exchangeCardsDemo[box] = cards[box]
                    isExchangeDemo[box] = 1
                    exchangeCardsDemo.forEach((array, index) => {
                        if (array && array.length > 0) {
                            [...array, {}].forEach((_, ind) => {
                                activePositions.push({ value: (5 - ind) * card_divide - (card_divide - (index + 1)), index: index })
                            })
                        }
                    })

                    activePositions.sort(function (a, b) { return a.value - b.value })
                    activePositions.sort(function (a, b) { return a.index - b.index })

                    this.setState({ isExchange: isExchangeDemo, exchangedCards: exchangeCardsDemo, active: activePositions[this.position].value, activePositions, game: demo_game })
                }

                if (data.key === SIXTH) {
                    this.setState({ game: demo_game })
                }

                if (data.key === BUY) {
                    this.setState({ isPurchase: true, purchaseCard: data.data, active: (this.state.boxes + 1) * 5, dealerGame: null })
                }

                if (data.key === PASS || data.key === BET) {
                    this.setState({ isOpen: true })
                }

            }, 400)
        })

        /* Get Sixth Card */
        socket.on("monitorFifth", data => {
            const { fifthCard } = this.state
            const { card, index } = data
            let cards = fifthCard
            cards[index] = card
            this.setState({ fifthCard: cards })
        })

        /* Get Exchanged Cards */
        socket.on("monitorExchangeCards", data => {
            const { player, activePositions, action } = this.state
            const { card, index } = data
            let cards = player
            cards[index] = cards[index] ? cards[index] : []
            cards[index].push(card)
            this.position += 1
            this.setState({ player: cards, active: activePositions[this.position] ? activePositions[this.position].value : 0 })

            if (this.position + 1 === activePositions.length) {
                let demo_action = action
                action.forEach((item, index) => {
                    if (item === 'exchange') {
                        demo_action[index] = 0
                    }
                })
                this.setState({ action: demo_action })
            }
        })

        socket.on("monitorExchangePlayerGame", data => {
            const { box } = data
            let demo_action = this.state.action
            demo_action[box] = 0
            this.setState({ action: demo_action })
        })

        /* GET Purchased Card */
        socket.on("monitorPurchaseCard", data => {
            const { dealer } = this.state
            let cards = dealer
            cards.push(data)
            this.setState({ dealer: cards })
        })

        /* Get Player Game */
        socket.on("monitorPlayerGame", data => {
            const { game } = this.state
            const { index, playerGame } = data
            let demo_game = game
            demo_game[index] = playerGame

            this.setState({ game: demo_game })
        })

        socket.on("monitorExchangeGame", data => {

            const { game } = this.state
            const { index, player_game } = data
            let demo_game = game
            demo_game[index] = player_game

            this.setState({ game: demo_game })
        })

        /* Get Dealer Game and Cards */
        socket.on("monitorDealerGame", data => {
            this.setState({ dealerGame: data })
            sound.audio('action', 1)
        })

        socket.on("monitorDealerCards", data => {
            this.setState({ dealer: data })
            sound.audio('action', 1)
        })

        /* Get Sixth Card */
        socket.on("dealerChoise", data => {
            if (data === "choise") {

                this.setState({
                    status: WAITING,

                    active: 1,
                    dealer: [],
                    player: [],

                    action: [0, 0],

                    activePositions: [],

                    fifthCard: [],

                    isExchange: [0, 0],
                    exchangedCards: [],

                    isPurchase: false,
                    purchaseCard: [],

                    isOpen: false,

                    endGame: false,

                    game: [0, 0],
                    dealerGame: null,

                    isPaused: false,
                })

                this.position = 0
            }
        })



        socket.on("monitorReconnection", data => {

            let tempPlayer = data.player
            const dealer = data.dealer
            const fifthCard = data.fifthCard
            const isPurchase = data.isPurchase
            const purchaseCard = data.purchaseCard
            const game = data.game
            const dealerGame = data.dealerGame
            const isExchange = data.isExchange

            data.newCards.forEach((item, i) => {
                if (item && item.length > 0) {
                    tempPlayer[i] = [...tempPlayer[i], ...item]
                }
            })

            this.setState({
                dealer,
                player: tempPlayer,
                fifthCard,
                isPurchase,
                purchaseCard,
                game,
                dealerGame,
                isPaused: false,
                isExchange,
                active: 1
            })

            if (data.status) {
                this.setState({
                    action: data.actions ? data.actions : ['', ''],
                    status: data.status,
                    boxes: data.boxes,
                    isPaused: data.isPaused,
                    isOpen: data.isOpen,
                    endGame: data.endGame,
                    dealerGame: data.dealerGame,
                    exchangedCards: data.exchangedCards,
                })
            }
        })



        /* Message event */
        socket.on("monitorMessage", data => {
            const { messages } = this.state

            if (messages.length === 50) {
                messages.pop()
            }

            messages.unshift(data)
            this.setState({ messages })

            sound.audio('message', 0.5)
        })


        /* Continue event */
        socket.on("monitorContinue", () => {
            this.setState({ isPaused: false })
        })

        /* Pause Event */
        socket.on("pause", () => {
            this.setState({ isPaused: true })
        })

    }


    clear = () => {

        this.setState({
            active: 1,
            dealer: [],
            player: [],
            action: [0, 0],
            activePositions: [],
            fifthCard: [],
            isExchange: [0, 0],
            exchangedCards: [],
            isPurchase: false,
            purchaseCard: [],
            isOpen: false,
            endGame: false,
            game: [0, 0],
            dealerGame: null,
            isPaused: false,
        })

        this.position = 0
    }


    _result = (index) => {

        const game = this.state.game[index] ? this.state.game[index] : null

        let result = ""


        if (game !== null) {
            if (parseInt(game.level) === 0) {
                result = "Нет игры"
            }
            if (parseInt(game.level) > 0) {
                result = game.name
                if (game.hasAceKing) {
                    result = result + " + Туз Король"
                }
            }
        }


        if (result !== "") {
            if (result === "Нет игры") {
                return <div className="player-result player-result-red">{result}</div>
            }
            else {
                return <div className="player-result">{result}</div>
            }
        }
    }


    /* Draw dealer game result */
    _result_dealer = () => {

        const { dealerGame, isOpen } = this.state

        if (isOpen) {
            if (dealerGame !== null) {
                if (parseInt(dealerGame.level) === 0) {
                    return <div className="dealer-result dealer-result-red">Нет игры</div>
                }
                if (parseInt(dealerGame.level) > 0) {
                    return <div className="dealer-result">{dealerGame.name}</div>
                }
            }
        }
    }

    /* Display Logo */
    _logo = () => {
        return (
            <div className="game-logo">
                <img src="/images/icon.png" alt="ICON" />
                <div className="game-logo-text">
                    <span>VIP</span>
                    <p>EUROPEAN</p>
                    <p>POKER</p>
                </div>
            </div>
        )
    }

    _time = () => {
        return (
            <div className="clock">
                {this.state.ctime}
            </div>
        )
    }

    /* Display User */
    _user = () => {

        const { username } = this.state

        if (username) {
            return (
                <div className="game-user user">
                    <img src="/images/user.png" alt="ICON" />
                    <div className="game-user-text">
                        Игрок <span>{username}</span>
                    </div>
                </div>
            )
        }

        return null

    }


    /* Display User */
    _dealerUser = () => {

        const { logout } = this.props

        const token = localStorage.getItem("token")
        const data = decodeToken(token)

        return (
            <div className="game-user dealer">
                <img src="/images/user.png" alt="ICON" />
                <div className="game-user-text">
                    Дилер <span>{data.name}</span>
                </div>
                <div onClick={() => logout()} className="game-user-power">
                    <img src="/images/power.png" alt="POWER" />
                </div>
            </div>
        )
    }



    /* Draw card image */
    _image = (card, index) => {

        const { isOpen } = this.state

        if (isOpen || index === 4) {
            return <img src={`/cards/${card.image}`} alt="Dealer Card" />
        }

        return <img src={`/cards/closed.webp`} alt="Dealer Card" />
    }



    /* Draw flip animation box */
    _flip = (card, index) => {

        const { isOpen } = this.state

        let isActive = ''

        if (isOpen || index === 4) {
            isActive = 'active'
        }

        return (
            <div className={`dealer-flip-card ${isActive}`}>
                <div className="dealer-flip-card-inner">
                    <div className="dealer-flip-card-front">
                        <img src={`/cards/closed.webp`} alt="Dealer Card" />
                    </div>
                    <div className="dealer-flip-card-back">
                        {this._image(card, index)}
                    </div>
                </div>
            </div>
        )
    }


    /* Draw dealer cards */
    _dealer = () => {

        const { dealer, active, purchaseCard, isPurchase, boxes } = this.state
        const numbers = [1, 2, 3, 4, 5]

        const card_divide = boxes + 1

        return (
            <div className="dealer">

                {/* Draw text */}
                <div className="title">Dealer</div>

                <div className="cards">

                    {/* Draw card spaces */}
                    <div className="spaces">
                        {numbers.map((item, index) => {
                            const number = item * card_divide
                            return (
                                <div key={`${index}`} className="space">
                                    {number}
                                    {number === active && <div className="space-active" />}
                                </div>
                            )
                        }
                        )}
                    </div>

                    {/* Draw cards */}
                    {dealer.map((item, i) => {
                        const index = purchaseCard.findIndex(e => parseInt(e.id) === parseInt(item.id))
                        return (
                            <div className={`card animation ${index > -1 ? `exchange` : ''}`} key={`${item.uid}`}>
                                {this._flip(item, i)}
                            </div>
                        )
                    })}

                    {isPurchase ?
                        <div className="animation-dealer-box">
                            {dealer.map((item, i) => {
                                const index = purchaseCard.findIndex(e => parseInt(e.id) === parseInt(item.id))
                                if (i <= 4) {
                                    return (
                                        <div className={`animation-card animation-dealer-card ${index === -1 ? 'hidden' : ''} `} key={`${item.uid}`}>
                                            <img src={`/cards/${item.image}`} alt="CARD" />
                                        </div>
                                    )
                                }
                                else {
                                    return <div />
                                }
                            })}
                        </div>
                        : null
                    }

                </div>
                {this._result_dealer()}
            </div>
        )
    }


    /* Draw Sixth Card */
    _fifth = (number) => {

        const { action, fifthCard } = this.state

        const card = fifthCard[number]

        return (
            <div className={`sixth-card ${(action[number] === SIXTH || card) ? 'sixth-card-active' : ''}`} key={`six-${number}`}>
                <div className="six_card_text">
                    <p>5-ая</p>
                    <span>карта</span>
                </div>

                <div className="sixth-space">
                    <div className="space">
                        +1
                        <div className="space-active" />
                    </div>
                </div>

                {/* Cards */}
                <div className="sixth-cards">
                    {card ?
                        <div className="card animation" key={`${card.id}`}>
                            <img src={`/cards/${card.image}`} alt="CARD" />
                        </div>
                        : null
                    }
                </div>

            </div>
        )
    }


    /* Draw Player Cards */
    _player = () => {

        const { player, boxes, active, exchangedCards, isExchange } = this.state
        const numbers = [1, 2, 3, 4, 5]
        let boxes_list = Array.apply(1, { length: boxes }).map(Number.call, Number)
        const card_divide = boxes + 1

        return (
            <div className="player" key={`main_box`}>

                {/* Title */}
                {
                    boxes_list.map((item, ind) => {
                        const box_index = ind + 1
                        const style_index = boxes_list.length === 2 ? ind : 2
                        return (
                            <div className={`box_outer_${ind}`} key={`${ind}`}>
                                <div className="box-status-text">
                                    {this._text(ind)}
                                </div>
                                <div className="box_number">{box_index + ' - BOX'}</div>
                                <div className="cards">
                                    {/* Spaces */}
                                    <div className="spaces">
                                        {numbers.map((item, index) => {
                                            const style = index === 4 && !isExchange[ind] ? "hidden" : ""
                                            return (
                                                <div key={`space-card-${index}`} className={`space ${style}`}>
                                                    {item * card_divide - (card_divide - box_index)}
                                                    {(item * card_divide - (card_divide - box_index)) === active && <div className="space-active" />}
                                                </div>
                                            )
                                        }
                                        )}
                                    </div>

                                    {/* Cards */}
                                    {player[ind] && player[ind].map(item => {
                                        const boxExchangeCards = exchangedCards[ind] ? exchangedCards[ind] : []
                                        const index = boxExchangeCards.findIndex(e => parseInt(e.id) === parseInt(item.id))
                                        return (
                                            <div className={`card animation ${index > -1 ? `exchange` : ''}`} key={`card-an-${item.id}`}>
                                                <img src={`/cards/${item.image}`} alt="CARD" />
                                            </div>
                                        )
                                    })}

                                    {isExchange[ind] ?
                                        <div className={`animation-box box_${style_index}`}>
                                            {player[ind] && player[ind].map((item, i) => {
                                                const boxExchangeCards = exchangedCards[ind] ? exchangedCards[ind] : []
                                                const index = boxExchangeCards.findIndex(e => parseInt(e.id) === parseInt(item.id))
                                                if (i <= 4) {
                                                    return (
                                                        <div className={`animation-card ${index === -1 ? 'hidden' : ''}`} key={`an_card_${item.id}`}>
                                                            <img src={`/cards/${item.image}`} alt="CARD" />
                                                        </div>
                                                    )
                                                }
                                                else {
                                                    return <div key={`an_card_${item.id}`} />
                                                }
                                            })}
                                        </div>
                                        : null
                                    }

                                </div>

                                {this._fifth(ind)}
                                {this._result(ind)}
                            </div>
                        )
                    })
                }
            </div>
        )

    }



    _text = (number) => {

        const { status, action, fifthCard } = this.state

        let color = ''

        if (status === NOPLAYER || status === WAITING) {
            color = 'black'
        }

        if (action[number] === BET) {
            color = 'green'
        }

        if (action[number] === PASS) {
            color = 'red'
        }

        if (action[number] === SIXTH && fifthCard.length === 0) {
            color = 'blue'
        }

        if (action[number] === EXCHANGE) {
            color = 'yellow'
        }

        if (action[number] === BET) {
            return <div className={`animation-text ${color}`}>BET</div>
        }

        if (action[number] === PASS) {
            return <div className={`animation-text ${color}`}>ПАС</div>
        }

        if (action[number] === SIXTH && fifthCard.length === 0) {
            return <div className={`animation-text ${color}`}>5-АЯ КАРТА</div>
        }

        if (action[number] === EXCHANGE) {
            return <div className={`animation-text ${color}`}>ОБМЕН</div>
        }

        return <div className={`animation-text ${color}`}></div>

    }



    /* Draw BOX */
    _box = () => {

        const { status, action, endGame } = this.state


        if (status === NOPLAYER) {
            return (
                <div className="waiting">
                    <h2>Игрок отсутствует</h2>
                </div>
            )
        }

        if (status === WAITING) {
            return (
                <div className="waiting">
                    <h2>Ожидание игрока ...</h2>
                </div>
            )
        }


        return (
            <div className="box">
                {endGame ? <div className={`animation-text head red`}>КОНЕЦ ИГРЫ</div> :
                    action[0] === BUY ? <div className={`animation-text head purple`}>ПОКУПКА ИГРЫ У ДИЛЕРА</div> : null}
                {this._player()}
                {this._dealer()}
                <div className="corner-box" />
            </div>
        )

    }

    /* Pause action */
    pause = () => {
        const { socket } = this.props
        this.setState({ isPaused: true })
        socket.emit("pause", { from: "dealer" })
    }

    /* Draw Pause */
    _pause = () => {

        const { status } = this.state

        if (status === GAME) {
            return (
                <div onClick={() => this.pause()} className="pause">

                    <img src="/images/pause.png" alt="Pause" />

                    <div className="pause-inner">
                        <div className="pause-inner-title">Приостановить игру</div>
                        <div className="pause-inner-text">и вызвать в службу поддержки</div>
                    </div>

                </div>
            )
        }

    }


    /* Draw Messages */
    _message = () => {

        const { messages, status } = this.state

        if (status === GAME || status === WAITING) {
            return (
                <div className="messages">
                    <div className="messages-title">Чат с игроком</div>

                    <div className="message-box">
                        {messages.map((message, index) => (
                            <div className="message" key={`${index}`} style={{ opacity: (1 - index * 0.1) }}>
                                <div className="message-name">{message.name}</div>
                                <div className="message-text">{message.message}</div>
                            </div>
                        ))}
                    </div>
                </div>
            )
        }

    }


    /* Draw pause modal */
    _pauseModal = () => {

        const { isPaused } = this.state

        return (
            <Modal
                isOpen={isPaused}
                className="pause-modal"
                overlayClassName="pause-overlay"
                closeTimeoutMS={200}
            >
                <div className="pause-modal-title">Игра приостановлена</div>
                <div className="pause-modal-text">Служба поддержки решает эту ситуацию. Пожалуйста, подождите.</div>
            </Modal>
        )

    }


    render = () => {

        const { action, fifthCard, status } = this.state

        let color = 'green'

        if (status === NOPLAYER || status === WAITING) {
            color = 'black'
        }

        if (action === BET) {
            color = 'darkgreen'
        }

        if (action === PASS) {
            color = 'red'
        }

        if (action === SIXTH && fifthCard.length === 0) {
            color = 'blue'
        }

        if (action === EXCHANGE) {
            color = 'yellow'
        }

        if (action === BUY) {
            color = 'purple'
        }

        return (
            <div className={`game ${color}`}>
                {this._logo()}
                {this._time()}
                {this._user()}
                {this._dealerUser()}
                {this._pause()}
                {this._box()}
                {this._message()}
                {this._pauseModal()}
            </div>
        )
    }

}

export default Game